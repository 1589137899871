section.section-horaires {
      transform: translate(0px);
      div.container-fluid.row {
          margin: 0;
          padding: 0;
      }

      div.title-box {
          padding: 15px 85px;
          width: 426px;
          background-color: $color-yellow;
          transform: translateY(50%);
          h3 {
              color: $color-black;
              font-size: $font-size-30;
              font-weight: 700;
          }
      }
      div.table-box {
          background-color: $color-black;
          padding: 50px 85px;
          margin-right: 5%;
          table {
              th {
                  color: $color-white!important;
                  font-size: $font-size-24!important;
                  text-transform: uppercase!important;
                  border-bottom: none;
              }
              td {
                  border-top: none;
                  color: $color-white!important;
              }
              h3, h4, h2 {
                  color: $color-yellow;
                  font-size: $font-size-20;
              }
              p {
                  color: $color-white!important;
                  font-size: $font-size-20;
              }
          }
      }
      @media screen and (max-width: $size-xs-max){
            div.title-box {
                padding: 15px;
                width: 100%;
                h3 {
                    text-align: center;
                }
            }
            div.table-box {
                background-color: $color-black;
                padding: 50px 15px;
                margin-right: 0;
                overflow-x: auto;
            }
      }
}
