h2, h3{
    margin: 0px;
    font-weight: normal;
}
.timeline {
    position: relative;
    max-width: 1600px;
    width: 95%;
    margin: 0 auto;
    padding-top: 30px;
    background: $color-black;
}
//header style
.timeline-left .header-content:after {
    content: " ";
    position:absolute;
    left:0px;
    top:40px;
    border-color:transparent;
    border-style: solid;
    border-width:0px 20px 20px 0px;
    border-right-color:#E7DB21;
}
.timeline-right .header-content:after{
    content: " ";
    position:absolute;
    right:0px;
    top:40px;
    border-color:transparent;
    border-style: solid;
    border-width:0px 0px 20px 20px;
    border-left-color:#E56F57;
}
.timeline-left .header-content:before{
    content: " ";
    position:absolute;
    top:0px;
    right:-40px;
    border:20px solid transparent;
    border-left-color:#FEC006;
}
.timeline-right .header-content:before{
    content: " ";
    position:absolute;
    top:0px;
    left:-40px;
    border:20px solid transparent;
    border-right-color:#FF7058;
}
.timeline-left .header-content,
.timeline-right .header-content{
    text-align: center;
    color:#fff;
    width:97%;
    padding: 5px;
    height: 40px;
    background-color: #FEC006;
    position: relative;
}
.timeline-right .header-content{
    background-color: #FF7058;
    margin-left:18px;
}
.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top:60px;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}
.timeline-right,
.timeline-left {
    padding: 10px 40px;
    position: relative;
    width: 50%;
    .image, .content {
        transform: scale(0);
        transition: .5s;
    }
    &.active {
        .image, .content {
            transform: scale(1);
            transition: .5s;
        }
    }
}

div[class^="timeline-"] {
    &:nth-child(n) {
        .image {
            background-color: $color-blue;
        }
        .content {
          border-left: 8px solid $color-blue;
          border-right: 8px solid $color-blue;
        }
    }
    &:nth-child(3n) {
        .image {
            background-color: $color-green;
        }
        .content {
          border-left: 8px solid $color-green;
          border-right: 8px solid $color-green;
        }
    }

    &:nth-child(3n + 1) {
        .image {
            background-color: $color-yellow;
        }
        .content {
          border-left: 8px solid $color-yellow;
          border-right: 8px solid $color-yellow;
        }
    }
}

.timeline-left .image,.timeline-right .image {
    position: absolute;
    width:70px;
    height: 70px;
    border-radius: 50%;
    border:5px solid #fff;
    right:-35px;
    top:7px;
    z-index:1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: $color-white;
}
.timeline-right .image {
    left:-35px;
}
.timeline-right {
    left: 50%;
}
.timeline-left .content,.timeline-right .content{
    padding:20px;
    background-color:$color-white;
    position: relative;
    width: 90%;
    border-radius: 5px;
    margin:0px 0px 0px 20px;
    text-align: left;
     p {
        line-height: 1.5;
     }
     h3, h4 {
        color: $color-brown;
     }
}
.timeline-right .content{
    left:15px;
}

@media (max-width:$size-xs-max) {
    .timeline-left .image,.timeline-right .image{
        left:5px;
    }
    .timeline::after {
        left:30px;
    }
    .timeline{
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
    .timeline-left{
        width: 100%;
    }
    .timeline-right{
        width: 100%;
        left: 0px;
    }

    .timeline-right .content,
    .timeline-left .content{
        width:95% !important;
        margin-left:20px !important;
        left: 15px;
        text-align: left;
        transition: .5px
    }
    .timeline-right .image,
    .timeline-left .image{
        font-size: 22px;
        width: 50px;
        height: 50px;
        transition: .5px
    }
    .timeline-left .header-content:before{
        right: unset;
        left:-40px;
        border:20px solid transparent;
        border-right-color:#FEC006;
    }
    .timeline-right .header-content:after{
        border-width:0px 0px 30px 26px;
    }
    .timeline-left .header-content:after{
        content: " ";
        position:absolute;
        left: unset;
        right:0px;
        top:40px;
        border-color:transparent;
        border-style: solid;
        border-width:0px 0px 30px 26px;
        border-left-color:#E7DB21;
    }
}
// @media (min-width:640px) and (max-width:768px) {
//     .timeline-right .content{
//         margin:0px 0 0 4px;
//     }
// }
