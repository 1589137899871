
body{
      &.services {
        position: relative;
          .blue-bar {
              top: 50px;
              width: 5%;
              z-index: 1;
              height: 20vw;
              position: absolute;
              background-color: $color-blue;
          }
          .green-bar {
              top: 50%;
              right: 0;
              width: 5%;
              z-index: 1;
              height: 20vw;
              position: absolute;
              background-color: $color-green;
          }
          .brown-bar {
              bottom: 100px;
              width: 30%;
              z-index: 0;
              height: 10vw;
              position: absolute;
              background-color: #5d3d4e;
          }
      }
      &.qns {
        position: relative;
          .blue-bar {
              top: 350px;
              width: 25%;
              z-index: 1;
              height: 50px;
              right: 0;
              position: absolute;
              background-color: $color-blue;
          }
          .green-bar {
              bottom: 120px;
              //right: 0;
              width: 5%;
              z-index: 1;
              height: 20vw;
              position: absolute;
              background-color: $color-green;
          }
          .yellow-bar {
              top: calc((100vw * 298 / 1749) - 42px );
              //bottom: 100px;
              width: 25%;
              z-index: 1;
              height: 40px;
              position: absolute;
              background-color: $color-yellow;
          }
      }
      &.equipe {
          position: relative;
          .green-bar {
              bottom: 100px;
              //right: 0;
              width: 25%;
              z-index: 1;
              height: 60px;
              position: absolute;
              background-color: $color-green;
          }
          .blue-bar {
              top: 50px;
              width: 25%;
              z-index: 1;
              height: 50px;
              position: absolute;
              background-color: $color-blue;
          }
      }
      &.emplois {
          position: relative;
          .brown-bar {
              top: 100px;
              width: 50px;
              z-index: 1;
              height: 300px;
              position: absolute;
              background-color: $color-brown;
          }
      }
      &.documents {
          position: relative;
        .green-bar {
            top: calc((100vw * 298 / 1749) - 42px );
            //right: 0;
            width: 15%;
            z-index: 1;
            height: 80px;
            position: absolute;
            background-color: $color-green;
        }
        .yellow-bar {
            top: calc((100vw * 400 / 1749) - 42px );
            width: 5%;
            right: 0;
            z-index: 1;
            height: 340px;
            position: absolute;
            background-color: $color-yellow;
        }
      }
      &.calendrier {
          position: relative;
          .green-bar {
              bottom: 100px;
              //right: 0;
              width: 40%;
              z-index: 1;
              height: 40px;
              position: absolute;
              background-color: $color-green;
          }
          .yellow-bar {
              top: calc((100vw * 400 / 1749) - 42px );
              width: 5%;
              right: 0;
              z-index: 1;
              height: 340px;
              position: absolute;
              background-color: $color-yellow;
          }
          .brown-bar {
              top: 100px;
              //right: 0;
              width: 5%;
              z-index: 1;
              height: 20vw;
              position: absolute;
              background-color: #5d3d4e;

          }
      }
      &.coordonnees {
          .yellow-bar {
              top: calc((100vw * 400 / 1749) - 42px );
              width: 50px;
              z-index: 1;
              height: 340px;
              position: absolute;
              background-color: $color-yellow;
          }
      }
      @media screen and (max-width: $size-xs-max){
            & {
                .yellow-bar,
                .green-bar,
                .brown-bar,
                .blue-bar {
                    display: none;
                }
            }
      }
}
