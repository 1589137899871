.header-equipe div.title-box {
    width: 60%;
    padding: 20px;
    margin: 20px 0;
    float: right;
    display: block;
    background-color: $color-yellow;
    h3 {
      color: $color-black;
      font-size: $font-size-36;
    }
    @media screen and (max-width: $size-xs-max){
        width: 100%;
        float: none;
        h3 {
            text-align: center;
        }
    }
}
section.section-conseil-admin {
      @extend .section-nous-joindre;
      margin: 150px 0;
      div.text-box {
          background-color: $color-black;
          width: 100%;
          div.description {
              background-color: $color-black;
              padding: 50px 150px 25px 150px;
              p {
                  line-height: 1.5;
                  color: $color-white;
              }
          }
      }
      @media screen and (max-width: $size-xs-max){
            margin: 50px 0;
            width: 100%;
            div.text-box {
                div.description {
                    padding: 50px 15px;
                    p {
                        text-align: center;
                    }
                }
            }
      }

}
