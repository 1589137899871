.bursts {
    width: 100%;
    padding: 7% 0;
    background: url('../images/accueil_section02_bg.jpg'), $color-white;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    .bursts-container {
        @extend .flex;
        justify-content: space-around;
        & > *:nth-child(1){
            transform: translate(0,-15%);
        }
        & > *:nth-child(2){
            transform: translate(0,15%);
        }
        & > * {
            width: 32.5%;
            transition: .3s
        }

        @media screen and (max-width: 600px){
          flex-direction: column;
          & > *:nth-child(1){
              transform: translate(0,0);
          }
          & > *:nth-child(2){
              transform: translate(0,0);
          }
          & > * {
              width: 100%;
              transition: .3s
          }
        }
        .burst {
            //flex: 1;
            width: 100%;
            position: relative;

            img {
                width: 100%;
            }
            .text-container, .overlay {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
            }
            .text-container {
                padding: 1.25rem;
                .border {
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    @extend .items-center;
                    transition: all 0.2s ease-out; // OUT effect
                }
            }
            .overlay {
                opacity: 0;
                transition: all 0.7s ease-out; // OUT effect
            }
            h3,h4 {
                color: $color-white;
                transition: all 0.2s ease-out; // OUT effect
            }
            h3 {
                @extend .font-2-normal;
                font-size: $font-size-34;
                text-transform: none;
            }

        }
        a:hover .burst {
            .overlay {
                opacity: 1;
                transition: all 0.3s ease; // IN effect
            }

            .border {
                //border-color: $color-secondary;
                transition: all 0.3s ease; // IN effect
            }
        }
    }
}
@media screen and (max-width: $size-slider-breakpoint) {
    .bursts {
        .bursts-container {
            flex-direction: column;
            .burst {
                h3 {
                    font-size: 2rem;
                }
                h4 {
                    font-size: 1rem;
                }
            }
        }
    }
}
