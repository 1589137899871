section.section-nous-joindre {
    width: 95%;
    max-width: 1600px;
    transform: translate(0px);
    background-color: $color-white;
    padding: 30px;
    margin: auto;
    div.container-fluid.row {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    div.image-container {
         width: 30%;
         max-width: 400px;
         padding: 15px;
         z-index: 1;
         margin-top: -50px;
         background-color: $color-white;
    }
    div.text-box {
        //padding: 50px 0;
        margin-left: -100px;
        h3 {
            font-weight: 700;
            color: $color-black;
            padding: 20px 150px;
        }
        div.description {
            background-color: #ebebeb;
            padding: 50px 150px 100px 150px;
            p {
                line-height: 1.5;
                color: $color-black;
                @extend .font-3-normal;
            }
        }
    }
    @media screen and (max-width: $size-xs-max){
        div.container-fluid.row {
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        div.image-container {
             width: 100%;
             max-width: 300px;
             margin-top: 0;
            img {
            }
        }
        div.text-box {
            margin-left: 0;
            width: 100%;
            h3 {
                text-align: center;
                padding: 20px;
            }
            div.description {
                padding: 50px 0;
                width: 100%;
                p {
                    text-align: center;
                }
            }
        }
    }
}
