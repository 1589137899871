section.section-01-emplois {


      div.container-fluid.row {
            padding: 0;
            margin: 0 auto;
            max-width: 1600px;
            width: 80%;
      }

      div.box-text-img {
            display: flex;
            justify-content: center;
            margin: 100px 0;
            div.text-box {
                  width: 70%;
                  background-color: $color-blue;
                  padding: 80px 5vw 80px 3vw;

                  p {
                      color: $color-white;
                      font-size: $font-size-30;
                      font-style: italic;
                      font-weight: 400;
                      width: 70%;
                      text-align: center;
                      margin: auto;
                  }
                  margin-right: -10%;
            }
      }

      div.image-box {
          margin-top: -5%;
      }

      @media screen and (max-width: $size-xs-max){
          div.box-text-img {
              flex-direction: column;
              div.text-box {
                  width: 100%;
              }
          }
          div.image-box {
              img {
                  margin: auto;
              }
          }
      }
}
