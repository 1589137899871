section.emplois-liste {
    padding: 85px 0;
    background-color: $color-grey-light;

    div.container-fluid.row {
        margin: 0 auto;
        padding: 0;
        width: 90%;
        max-width: 1600px;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        & > div {
            width: 40%;
            transition: .5s;
        }
        @media screen and (max-width: $size-xs-max){
            flex-direction: column;
            & > div {
                width: 100%;
                transition: .5s;
                margin-bottom: 30px;
            }
        }
    }

    div.item-poste {
        h3 {
            font-size: $font-size-40;
            color: $color-black;
            font-weight: 700;
        }
        h4 {
            font-size: $font-size-24;
            color: $color-black;
            font-weight: 700;
            padding: 20px 0;
        }
        p {
            line-height: 1.5;
            font-size: $font-size-20;
            @extend .font-2-normal;
        }
        hr {
            border-top: 1px solid $color-black;
            margin-bottom: 30px;
        }
        a.btn-moins, a.btn-plus {
            color: $color-black;
            cursor: pointer;
            &:hover {
              color: $color-yellow;
            }
        }
    }
}
