.btn {
    border-radius: $round-corner-radius;
    background-color: $color-black;
    transition: all 0.2s ease-in;
    font-size: $font-size-20;
    color: $color-white;
    text-transform: uppercase;
    max-width: 250px;
    padding: 15px;
    width: 100%;
    span.btn-em {
        font-size: $font-size-20;
        text-transform: uppercase;
        font-weight: 600;
    }
    &:hover {
        transition: all 0.2s ease-in;
        background-color: $color-yellow;
        color: $color-black;
    }
}
