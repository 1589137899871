section.section-01-documentation {

    background-color: $color-grey-light;
    padding: 50px;

    div.container-fluid.row {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        align-items: stretch;
        justify-content: center;
    }

    div.document-item {
        width: 30%;
        margin: 30px 15px;
    }

    h3 {
        color: $color-black;
        font-weight: 700;
    }
    @media screen and (max-width: $size-xs-max){
      padding: 50px 15px;
      div.document-item {
          width: 100%;
          max-width: 250px;
      }
    }
}
