.module-header {
    position: relative;
    margin-top: $size-nav-height;
    background-color: $color-white;
    padding-bottom: 50px;
    @media screen and (max-width: $size-nav-shift-breakpoint){
        margin-top: $size-nav-height-mobile;


        @media screen and (max-width: $size-nav-toggle-breakpoint){
            margin-top: 75px;
        }
    }
    &--image {
        margin-left: 10%;
        @media screen and (max-width: $size-xs-max){
            margin-left: 0%;
        }
    }

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-100%);
        z-index: $z-index-slide-caption;

        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
            a .overlay {
                background-color: rgba(0, 0, 0, 0);
            }
        }

    }
}
@media screen and (max-width: $size-md-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 6vw;
            }
            &--subtitle {
                font-size: 4vw;
            }
        }
    }
}
@media screen and (max-width: $size-sm-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 7vw;
            }
            &--subtitle {
                font-size: 5vw;
            }
        }
    }
}
@media screen and (max-width: $size-xs-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 8vw;
            }
            &--subtitle {
                font-size: 6vw;
            }
        }
    }
}
