section.services-style-pages {
    width: 85%;
    max-width: 1600px;
    background-color: $color-white;
    padding: 30px;
    margin: auto;
    div.container-fluid.row {
        margin: 0;
        padding: 0;
    }

    div.text-box {
        h3, h4 {
            color: $color-black;
            font-size: $font-size-30;
            padding: 25px 0;
        }
        p {
            color: $color-black;
        }
        ul {
          li {
              color: $color-black;
              text-transform: none;
              line-height: 2;
              font-size: $font-size-20;
          }
       }
       a.pdf-boutton {
          background-color: $color-yellow;
          font-size: $font-size-24;
          color: $color-black;
          padding: 20px 40px;
          transition: .3s;
          margin: 30px 0;
          font-weight: 700;
          display: inline-block;
          text-transform: uppercase;
          &:hover, &:focus {
              transition: .3;
              background-color: $color-white;
          }
       }
       div.image-container {
            float: right;
            //width: 100%;
            max-width: 30%;
            padding: 15px;
            margin: 25px -65px 20px 20px;;
            background-color: $color-white;
             img {
                  transform: translate(0px);
                  shape-margin: 0.8em;
                  shape-image-threshold: 0.5;
                  shape-outside: attr(src url);
             }
       }

       @media screen and (max-width: $size-xs-max){
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          div.image-container {
              float: none;
              width: 100%;
              max-width: 300px;
              margin: 30px auto;
          }

          h3, h4 {
              text-align: center;
          }
          p {
              text-align: center;
          }
          ul {
              max-width: 350px;
              margin: auto;
          }
       }
    }
}
