section.section-01-coordonnees {
    padding: 100px 0 50px;

    div.container-fluid.row {
        margin: 0;
        padding: 0;
    }

    div.wrapper {
        width: 80%;
        float: right;
        display: flex;
        div.left-side {
            width: 450px;
            transform: translate(50px, -100px);
            div.text-bg {
                background-color: #5d3d4e;
                padding: 2rem;
                display: flex;
                justify-content: center;
            }

            h3 {
                color: $color-black;
                padding-bottom: 10px;
                font-weight: 700;
                font-size: $font-size-34;
            }

            p {
                color: $color-white;
                line-height: 2;
                font-size: $font-size-24;
                @extend .font-3-normal;
            }
        }

        div.right-side {
            background-color: $color-black;
            padding: 25px 25px 25px 80px;
            width: 100%;
            display: flex;
            align-items: center;
            p {
                color: $color-white;
                @extend .font-3-normal;
                line-height: 1.3;
            }
        }
    }

    @media screen and (max-width: $size-xs-max){
        div.wrapper {
            flex-direction: column;
            float: none;
            margin: auto;
            div.right-side, div.left-side {
                width: 100%;
                transform: translate(0,0);
                padding: 25px 10px;
                justify-content: center;
                h3, p {
                    text-align: center;
                }
            }
        }
    }
}
