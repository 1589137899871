section.section-01-faq {

      div.container-fluid.row.item {
          margin: 0;
          padding: 0 0 50px 0;
      }

      div.title-box {
          width: 90%;
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
          max-width: 1600px;

          div.question-num {
              background-color: $color-yellow;
              width: 300px;
              min-width: 250px;
              display: flex;
              min-height: 75px;
              align-items: center;
              justify-content: center;
              h3 {
                  color: $color-black;
                  text-align: center;
                  font-weight: 700;
              }
          }

          div.question-title {
              padding-left: 25px;
              display: flex;
              min-height: 75px;
              align-items: center;
              justify-content: flex-start;

              h3 {
                  color: $color-black;
                  font-weight: 700;
              }
          }
      }
      div.question-reponse {
          width: 90%;
          max-width: 1600px;
          margin: 20px auto;
      }
      @media screen and (max-width: $size-xs-max){
          div.title-box {
              flex-wrap: wrap;
              div.question-title {
                  margin-top: 20px;
                  width: 100%;
              }
          }
      }
}
