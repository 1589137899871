section.section-activites {
    padding: 50px 0 0;
    transform: translate(0px);
    div.container-fluid.row {
        margin: 0;
        padding: 0;
        padding-left: 85px;
    }

    div.title-box {
        h3 {
            color: $color-black;
            font-size: $font-size-30;
            padding: 25px 15px;;
        }
    }

    div.table-box {
        table {
            thead {
                background-color: $color-blue;
            }
            th {
                color: $color-black!important;
                font-size: $font-size-24!important;
                text-transform: uppercase!important;
                border-bottom: none;
            }
            td {
                border-top: none;
                color: $color-black!important;
            }
            h3, h4, h2 {
                color: $color-black;
                font-size: $font-size-20;
            }
            p {
                color: $color-black!important;
                font-size: $font-size-18;
            }
        }
    }
    @media screen and (max-width: $size-xs-max){
          div.container-fluid.row {
              padding-left: 0;
          }
          div.title-box {
              padding: 15px;
              width: 100%;
              h3 {
                  text-align: center;
              }
          }
          div.table-box {
              padding: 50px 15px;
              margin-left: 0;
              overflow-x: auto;
          }
    }
}
