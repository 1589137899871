.slideshow-wrap {
    position: relative;
    background-color: black;
    //#layerslider { display: block; }
    //#layerslider-mobile { display: none; }
    //@media screen and (max-width: $size-slider-breakpoint) {
    //    #layerslider { display: none; }
    //    #layerslider-mobile { display: block; }
    //}

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }


    .ls-l a {
        width: auto;
    }

    .layer-text {
        background-color: $color-grey-light;
        padding: 20px;
        border: 10px solid $color-white;
    }

    .caption {
        position: absolute;
        top: 50%;
        right: 60px;
        transform: translateY(-50%);
        z-index: $z-index-slide-caption;

        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          margin: 10px;
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}
        // .caption {
        //     top: $size-nav-height-mobile + 20px;
        // }

    }
}
