// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;
    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

main {
    background-color: $color-white;
    padding: 30px 0;
}
/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;
    &::after {
        content: '';
        top: 100%;
        z-index: 0;
        width: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
//@import 'modules/menu';
// @import 'menu-centre';
 @import 'modules/menu-etage';

 @import 'modules/module-header';
/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
@import 'sections/systme-de-barre-decorative';

@import 'sections/section-bursts';

@import 'sections/section-timeline';

@import 'sections/section-mission-style-pages';

@import 'sections/section-services-style-pages';

@import 'sections/section-nous-joindre';

@import 'sections/section-table-horaires';

@import 'sections/section-table-activites';

@import 'sections/section-conseil-admin';

@import 'sections/section-01-emplois';

@import 'sections/section-emplois-liste';

@import 'sections/section-01-documentation';

@import 'sections/section-01-faq';

@import 'sections/section-01-coordonnees';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/

@import 'modules/module-slideshow';
//@import 'modules/module-header';
//@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/
section.section-01-paragraph {
      background-color: $color-white;
      position: relative;
      padding: 100px 10%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
            line-height: 1.7;
            text-align: center;
            font-size: $font-size-24;
            color: $color-grey-darker
      }
      div.yellow-bar {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 25%;
            height: 30px;
            transition: .3s;
            background-color: $color-yellow;
            @media screen and (max-width: $size-xs-max){
                  width: 50%;
                  transition: .3s;
            }
      }
}

section.section-carousel-logos {
      padding: 100px 5%;
      background-color: $color-white;
}

/*******************************************************************************
* PAGE X
*/

.pageX {
    section.header {

    }
}

section.section-calendrier {
    overflow-x: auto;
    padding: 50px 0;

    #calendar {
        width: 100%!important;
        min-width: 600px;

        .events_list_inline {
            font-size: 13px;
        }
    }
}
/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max) {

}

@media screen and (max-width: $size-sm-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}


@media screen and (max-width: $size-xs-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}
